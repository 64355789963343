<template>
	<div class="pa-0 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<!-- 타이틀 -->
			<v-col
				align="center"
				class="pa-0"
			>
				<v-row class="ma-0 py-5 justify-center main_tit">
					<v-col
						class="pa-0"
						cols="auto"
						align-self="center"
					>
						<v-img
							width="24"
							eager
							:src="require('@/assets/img/careflo/icon_care_flo.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0 ml-1"
						align-self="center"
						cols="auto"
					>
						케어플로 서비스
					</v-col>
				</v-row>
			</v-col>

			<!-- 구매자정보입력 -->
			<v-col
				cols="12"
				class="pa-4 mb-2 form section"
			>
				<h3 class="mb-2">구매자 정보 입력</h3>
				<p class="mb-5 desc">
					구매자 정보를 입력해주세요 이름과 휴대폰 번호는 추후 접속 시 고객님의 접속 ID와 PW로 활용됩니다.
				</p>

				<!-- 이름 -->
				<p class="mb-2 item">이름</p>
				<CommonInputsInput05
					v-model="name"
					class="mb-4"
					rule-name="required"
					:height="40"
					placeholder="이름"
				/>

				<!-- 전화번호 -->
				<p class="mb-2 item">휴대폰번호</p>
				<v-row class="ma-0 mb-2">
					<v-col
						class="pa-0"
						cols="8"
					>
						<div class="mr-1">
							<CommonInputsInput05
								v-model="hand"
								rule-name="tel"
								:height="40"
								type="number"
								placeholder="휴대폰번호 (-없이 입력)"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
					>
						<CommonButtonsButton01
							class="pa-0 btn_confirm"
							:disabled="hand === null"
							name="인증번호 발송"
							:color="smsNumberCheck ? 'primary' : '#262626'"
							:height="40"
							@click="checkSms(sms_check_type[11])"
						/>
						<v-col
							cols="12"
							class="pa-0 pr-1"
							align="end"
						>
							<p
								v-if="smsNumberCheck"
								style="font-size: 12px; color: #00ce7d"
							>
								인증되었습니다.
							</p>
						</v-col>
					</v-col>
				</v-row>
				<v-row
					v-if="smsNumberModel"
					class="ma-0 mb-4"
				>
					<v-col
						class="pa-0"
						cols="8"
					>
						<div class="mr-1">
							<CommonInputsInput05
								v-model="smsNum"
								:height="40"
								type="number"
								placeholder="인증번호 입력"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
					>
						<CommonButtonsButton01
							class="btn_confirm"
							name="인증확인"
							color="#262626"
							:height="40"
							@click="confirmSmsNumber"
						/>
						<v-col
							cols="12"
							class="pa-0 pr-2 pt-1"
							align="end"
							style="color: #fa2a6f; font-size: 12px"
						>
							{{ sms_check_timer }}
						</v-col>
					</v-col>
					<CommonLoaderLine01
						v-if="telLoading"
						class="mt-1"
					/>
				</v-row>

				<!-- 주소 -->
				<v-col
					cols="12"
					align="start"
					class="pa-0"
				>
					<p class="mb-2 item">시공주소</p>
					<v-row class="ma-0 pa-0">
						<v-col
							cols="8"
							class="pa-0"
						>
							<div class="mr-1">
								<CommonInputsInput03
									v-model="zipcode"
									:height="40"
									placeholder="우편번호"
									readonly
									disabled
									class="mb-2"
								/>
							</div>
						</v-col>
						<v-col
							cols="4"
							class="pa-0"
						>
							<CommonButtonsButton01
								class="btn_confirm"
								name="주소검색"
								color="#262626"
								:height="40"
								@click="postApi"
							/>
						</v-col>
					</v-row>
					<v-col
						cols="12"
						class="pa-0"
					>
						<CommonInputsInput03
							v-model="addr1"
							placeholder="도로명/지번주소"
							readonly
							disabled
							:height="40"
							class="mb-2"
						/>
					</v-col>

					<p class="mb-2 item">상세주소</p>
					<v-col
						cols="12"
						class="pa-0"
					>
						<CommonInputsInput03
							v-model="addr2"
							:height="40"
							placeholder="상세 주소를 입력해주세요."
						/>
					</v-col>
				</v-col>
			</v-col>

			<!-- 약관동의 -->
			<v-col
				cols="12"
				class="pa-4 mb-5 form section"
			>
				<h3 class="mb-2">약관동의</h3>
				<v-col class="pa-0">
					<v-col
						align="start"
						class="checkbox_group pa-2 mb-7"
					>
						<CommonCheckboxsCheckbox02All
							v-model="allValue"
							text="전체 동의"
							class="pa-0"
							@click="allowAll"
						/>
						<v-divider class="my-1" />
						<v-col class="pa-0">
							<CommonCheckboxsCheckbox02
								v-model="allow.age"
								text="(필수) 만 14세 이상입니다."
								class-name="justify-start"
							/>
							<CommonCheckboxsCheckbox02
								v-model="allow.use_yn"
								sub-text="(필수)"
								link="이용약관"
								href="/site/terms"
								text="에 동의"
								class-name="justify-start"
							/>
							<CommonCheckboxsCheckbox02
								v-model="allow.info"
								sub-text="(필수)"
								link="회원정보수집"
								href="/site/userInfo"
								text="에 동의"
								class-name="justify-start"
							/>
							<CommonCheckboxsCheckbox02
								v-model="allow.email"
								text="(선택) 이벤트, 프로모션 알림 및 email 수신에 동의"
								class-name="justify-start"
							/>
						</v-col>
					</v-col>
				</v-col>
			</v-col>

			<!-- 정보입력완료 -->
			<v-col class="pa-0 px-4">
				<CommonButtonsButton01
					class="mb-5 btn_clear"
					name="구매자 정보 입력 완료"
					color="#78C046"
					:height="42"
					:disabled="allowValue"
					@click="saveUserInfo"
				/>

				<div class="mb-15 center_info">
					<v-row class="ma-0 justify-center">
						<v-col
							class="pa-0"
							cols="auto"
							align="end"
						>
							<div class="mr-1 txt_1">고객센터</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="auto"
						>
							<div class="ml-2">
								<v-col class="pa-0 txt_2">1899-2837</v-col>
								<v-col class="pa-0 txt_3">평일 9:00 ~ 17:00(공휴일 외)</v-col>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import sms from '@/mixins/sms'
import kakao_post from '@/mixins/kakao_post'
export default {
	name: 'CareFloCustomerVerification',
	mixins: [common, model, sms, kakao_post],
	data: () => ({
		allow: {
			age: false,
			use_yn: false,
			info: false,
			email: false,
		},
		name: null,
		hand: null,
		smsNum: null,
		zipcode: null,
		addr1: null,
		addr2: null,

		// sms
		smsNumberCheck: false,
		smsNumberModel: false,
		telLoading: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
		allValue() {
			return Object.keys(this.allow).every(element => this.allow[element] === true)
		},
		allowValue() {
			let checkValue = ['age', 'use_yn', 'info']
			let check = false
			for (let i of checkValue) {
				if (this.allow[i] === false) check = true
			}
			if (check) return true

			checkValue = ['name', 'hand', 'zipcode', 'addr1', 'smsNumberCheck']

			for (let i of checkValue) {
				if (this[i] === null || this[i] === false) check = true
			}

			return check
		},
	},
	watch: {
		sms_check_timer(val) {
			if (val === '00:00') {
				this.smsNumberCheck = false
				this.smsNumberModel = false
			}
		},
	},
	mounted() {},
	methods: {
		...mapActions(['CAREFLO_ACT_CUSTOM_INFO_SAVE']),
		async checkSms(type) {
			this.telLoading = true
			let params = {
				type: type,
				hand: this.hand,
				name: this.name,
				login_id: '',
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		allowAll() {
			let val = true
			if (this.allValue) val = false

			Object.keys(this.allow).forEach(element => {
				this.allow[element] = val
			})
		},
		async postApi() {
			await this.kakao_post_api_call(res => {
				this.addr1 = res.address
				this.zipcode = res.post
			})
		},
		async saveUserInfo() {
			const params = {
				nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code,
				cust_name: this.name,
				cust_hand: this.hand,
				zipcode: this.zipcode,
				address1: this.addr1,
				address2: this.addr2,
			}

			await this.CAREFLO_ACT_CUSTOM_INFO_SAVE(params).then(res => {
				console.log('res', res)
				if (res) this.$router.push(`/careflo/customer/clear`)
			})
		},
	},
}
</script>

<style scoped lang="scss">
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.section {
	background-color: $color_white;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
	}
	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.checkbox_group {
	min-height: 180px;
	border-radius: 8px;
	background-color: #f8f8f8;
}

.btn_clear {
	:deep(.v-btn__content) {
		color: $color_white;
		font-weight: $fw_bold;
		font-size: $font_lg;
	}
}

.btn_confirm {
	:deep(.v-btn__content) {
		font-size: $font_normal !important;
	}
}

.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

:deep(.v-text-field__details) {
	padding: 0 !important;
	margin: 0 !important;
}
</style>
